import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const Sales = Loadable(lazy(() => import("src/pages/admin/sales/index")));

const sales = [
  {
    path: "sales",
    element: <Sales />,
  },
];

export default sales;
