import { useState } from "react";
import { Outlet } from "react-router-dom";
import ImpersonationBanner from "src/components/impersonationBanner";
import useCollapseDrawer from "src/hooks/useCollapseDrawer";
import {
  DashboardHeader,
  HorizontalWrapper,
  MainStyle,
  NavbarVertical,
} from "../shared";
import { Alert } from "@mui/material";
import useAuth from "src/hooks/useAuth";
import useLocales from "src/hooks/useLocales";

const Horizontal = ({ navConfig }) => {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const { translate } = useLocales();
  return (
    <HorizontalWrapper>
      <DashboardHeader
        isCollapse={isCollapse}
        onOpenSidebar={() => setOpen(true)}
      />

      <NavbarVertical
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
        config={navConfig}
      />

      <MainStyle collapseClick={collapseClick}>
        <ImpersonationBanner />
        {user.is_super_admin === 0 && user.email_verified_at === null ? (
          <Alert sx={{ mt: 1, mb: 2 }} severity="warning">
            {translate("yourmailverify")}
            <strong
              style={{
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              {/* Click here */}
            </strong>{" "}
            {/* to verify. */}
          </Alert>
        ) : (
          ""
        )}

        <Outlet />
      </MainStyle>
    </HorizontalWrapper>
  );
};

export default Horizontal;
